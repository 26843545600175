<template>
  <div class="row">
    <div class="footer">
      <img src="@/assets/footer.svg" alt="">
    </div>
  </div>
</template>

<script>
import {useCounterStore} from "@/state/pinia/stefan";

export default {
  name: "pageFooter",
  computed: {
    order() {
      // Accessing the store inside a computed property
      const store = useCounterStore();
      return store.order; // Now correctly returns the order from the store
    }
  },
  mounted() {
    // Example of using the store in lifecycle hooks
    const store = useCounterStore();
    console.log("Current order:", store.order);
  },
}
</script>

<style scoped>
.logo{
  max-width: 220px;
  height: auto;
  margin-top: 20px;
}
 input{
  border-radius: 35px;
  border: 1px solid #000000;
  padding: 10px 10px 0;
  margin-left: 20px;
   background-color: white;
}
 label{
  margin-top: 20px;
}
</style>
