<template>
  <div style="padding: 20px">
    <pageHeader></pageHeader>
    <div style="display: flex;justify-content: space-between;align-items: end;margin-top: 60px">
      <div style="display: flex;flex-direction: column;gap: 10px">
        <div class="boxHolder" style="  background: rgb(220 137 185 / 20%);">
          <h3>Humankapital</h3>
          <div class="Box">
            <label for="">Antal Goodwillklossar:</label>
            <input type="number" min="1" max="15" v-model="order.Humankapital.goodWill"
                   @input="resetTo('Humankapital','goodWill',15)">
            <span>Max 15 klossar</span>
          </div>
          <div class="Box">
            <label for="">Antal Hållbarhetklossar:</label>
            <input type="number" min="1" max="5" v-model="order.Humankapital.hallbar"
                   @input="resetTo('Humankapital','hallbar',5)">
            <span>Max 5 klossar</span>
          </div>
          <div class="stefanBtn" style="display: flex;justify-content: end;align-items: center;gap: 5px"
               @click="resetToOne('Humankapital')"
          >
            <img src="@/assets/trash.svg" alt="" style="width: 20px;">
            <span style="font-size: 8pt">RENSA</span>

          </div>

        </div>
        <div class="boxHolder" style="  background: rgb(243 146 0 / 40%);">
          <h3>Kundkapital </h3>
          <div class="Box">
            <label for="">Antal Goodwillklossar:</label>
            <input type="number" min="1" max="15" v-model="order.Kundkapital.goodWill"
                   @input="resetTo('Kundkapital','goodWill',15)">
            <span>Max 15 klossar</span>
          </div>
          <div class="Box">
            <label for="">Antal Hållbarhetklossar:</label>
            <input type="number" min="1" max="5" v-model="order.Kundkapital.hallbar"
                   @input="resetTo('Kundkapital','hallbar',5)">
            <span>Max 5 klossar</span>
          </div>
          <div class="stefanBtn" style="display: flex;justify-content: end;align-items: center;gap: 5px"
               @click="resetToOne('Kundkapital')">
            <img src="@/assets/trash.svg" alt="" style="width: 20px;">
            <span style="font-size: 8pt">RENSA</span>

          </div>
        </div>
        <div class="boxHolder" style="  background: rgb(0 159 227 / 20%);">
          <h3>Strukturkapital </h3>
          <div class="Box">
            <label for="">Antal Goodwillklossar:</label>
            <input type="number" min="1" max="15" v-model="order.Strukturkapital.goodWill"
                   @input="resetTo('Strukturkapital','goodWill',15)">
            <span>Max 15 klossar</span>
          </div>
          <div class="Box">
            <label for="">Antal Hållbarhetklossar:</label>
            <input type="number" min="1" max="5" v-model="order.Strukturkapital.hallbar"
                   @input="resetTo('Strukturkapital','hallbar',5)">
            <span>Max 5 klossar</span>
          </div>
          <div class="stefanBtn" style="display: flex;justify-content: end;align-items: center;gap: 5px"
               @click="resetToOne('Strukturkapital')">
            <img src="@/assets/trash.svg" alt="" style="width: 20px;">
            <span style="font-size: 8pt">RENSA</span>

          </div>
        </div>
        <div class="boxHolder" style="  background: rgb(149 193 31 / 40%);">
          <h3>Samhällskapital </h3>
          <div class="Box">
            <label for="">Antal Goodwillklossar:</label>
            <input type="number" min="1" max="15" v-model="order.Samhallskapital.goodWill"
                   @input="resetTo('Samhallskapital','goodWill',15)">
            <span>Max 15 klossar</span>
          </div>
          <div class="Box">
            <label for="">Antal Hållbarhetklossar:</label>
            <input type="number" min="1" max="5" v-model="order.Samhallskapital.hallbar"
                   @input="resetTo('Samhallskapital','hallbar',5)">
            <span>Max 5 klossar</span>
          </div>
          <div class="stefanBtn" style="display: flex;justify-content: end;align-items: center;gap: 5px"
               @click="resetToOne('Samhallskapital')"
          >
            <img src="@/assets/trash.svg" alt="" style="width: 20px;">
            <span style="font-size: 8pt">RENSA</span>

          </div>
        </div>
      </div>
      <div>
        <img src="@/assets/btnSkapaTKors.svg" alt="" @click="order.page = 3" class="stefanBtn d-none"
             style="  max-width: 180px;width: 180px;">
      </div>
    </div>

    <pageFooter style="padding-top: 30px;margin-top: 65px"></pageFooter>
  </div>
</template>

<script>
import pageHeader from "@/components/pageHeader";
import pageFooter from "@/components/pageFooter";
import {useCounterStore} from "@/state/pinia/stefan";

export default {
  name: "secondPage",
  components: {
    pageHeader, pageFooter
  },
  computed: {
    order() {
      // Accessing the store inside a computed property
      const store = useCounterStore();
      return store.order; // Now correctly returns the order from the store
    }
  },
  methods: {
    resetTo(ob, key, val) {
      const store = useCounterStore();
      if (store.order[ob][key] > val) {
        store.order[ob][key] = val
      }

    },
    resetToOne(ob) {
      const store = useCounterStore();
      store.order[ob].goodWill = 1
      store.order[ob].hallbar = 1
    },
  }
}
</script>

<style scoped>

.boxHolder {
  border-radius: 8px;
  padding: 15px;
}

.boxHolder h3 {
  font-size: 16pt;
  font-weight: 400;
  padding-bottom: 10px;
}

.boxHolder span {
  font-size: 10pt;
  font-weight: 300;
}

.boxHolder label {
  font-weight: 300;
  font-size: 16pt;
  width: 210px;
}

.Box {
  display: flex;
  gap: 15px;
  align-items: center;
  margin-bottom: 10px;
}

.Box input {
  background-color: white;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 2px;
  height: 30px;
  width: 60px;

}
</style>
