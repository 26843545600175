<template>
  <div class="repportApp">
    <page v-if="order.page === 1">
      <div class="row">
        <div class="logoHolder">
          <img src="@/assets/logo.svg" alt="" class="logo">
        </div>
        <h1>
          Välkommen till Abditums
          Goodwill & Hållbarhetsrapport
        </h1>

        <div class="containerBody">
          <div class="pageBody">
            <p>Efter att du genomfört en handledning med ett företag...</p>

            <ul>
              <li>Skriv in värden under resp kapital, både antal Goodwill- klossar och Hållbarhetsklossar.</li>
              <li>Skapa T-kors genom att klicka på ikonen och skriv ut eller spara som PDF via din skrivarfunktion</li>
              <li>Avsluta genom att fyll i anteckningar under resp . styrkor & svagheter. Skriv ut eller spara som
                PDF.
              </li>
            </ul>
          </div>
          <div class="pageBody2">
            <p>GDPR: Ingen data sparas efter du släckt ner fönstret</p>

            <div class="createRepport" style="margin-top: 220px">
              <p style="margin-top: 80px;">Skapa rapport genom att skriva in datum och företag och <span class="strong">Skapa rapport</span>
              </p>
              <div class="formHolder">
                <div class="form-group">
                  <label for="">Datum: <input type="date" v-model="order.date"></label>
                  <div class="hasBtn">
                    <label for="">Företag: <input type="text" v-model="order.company"></label>
                    <img src="@/assets/btnSkapa.svg" alt="" @click="order.page = 2" class="stefanBtn d-none" >
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="footer">
            <img src="@/assets/footer.svg" alt="">
          </div>
        </div>

      </div>
    </page >
    <page >
      <secondPage />
    </page>
    <page>
      <ThirdPage/>
    </page>
    <page >
      <fourthPage/>
    </page>
    <page >
      <fifthPage/>
    </page>
    <page >
      <sixthPage/>
    </page>


  </div>

</template>

<script>
import {useCounterStore} from "@/state/pinia";
import secondPage from "@/components/secondPage";
import ThirdPage from "@/components/ThirdPage";
import fourthPage from "@/components/fourthPage";
import fifthPage from "@/components/fifthPage";
import sixthPage from "@/components/sixthPage";

export default {
  name: "repportMain",
  components: {secondPage, ThirdPage,fourthPage,fifthPage, sixthPage},
  data() {
    return {
      // Initialize local component state if needed
    };
  },
  computed: {
    order() {
      // Accessing the store inside a computed property
      const store = useCounterStore();
      return store.order; // Now correctly returns the order from the store
    }
  },
  mounted() {
    // Example of using the store in lifecycle hooks
    const store = useCounterStore();
    console.log("Current order:", store.order);
  },
  methods: {
    // Example method to show usage within component methods
    updateCompany() {
      const store = useCounterStore();
      store.order.company = "New Company Name"; // Example of updating the store
    }
  }
}
</script>
<style scoped>
.logoHolder {
  display: flex;
  justify-content: end;
}

h1 {
  text-align: center;
  max-width: 480px;
  float: none;
  margin: auto;
  margin-top: -50px;
  font-width: 600;
}

.logo {
  height: 120px;
  margin-top: 20px;
}

.containerBody {
  padding-left: 40px;
  padding-right: 40px;
}

.pageBody {
  font-size: 16pt;
  max-width: 580px;
  margin: auto;
}

.pageBody2 {
  font-size: 16pt;
  max-width: 700px;
  margin: auto;
  padding-bottom: 40px;
}

.pageBody2 P {
  font-weight: 300;
}

.strong {
  font-weight: 500;
}

.pageBody p {
  font-weight: 300;
}

.pageBody {
  margin-top: 60px;
}

.pageBody ul {
  padding-top: 0px;
}

.pageBody ul li {
  list-style: decimal;
}

.pageBody ul li:not(:first-of-type) {
  padding-top: 40px;
}

.createRepport .form-group .hasBtn {
  display: flex;
  justify-content: space-between;
}

.createRepport .form-group img {
  max-width: 180px;
}

.createRepport input {
  border-radius: 35px;
  border: 1px solid #000000;
  padding: 10px 10px;
  margin-left: 20px;
}

.createRepport label {
  margin-top: 20px;
}

.createRepport .hasBtn input {
  min-width: 200px;
}

.row {
  position: relative;
}
</style>
