<template>
  <div style="padding: 20px">
    <pageHeader></pageHeader>
    <div style="display: flex;justify-content: space-between;align-items: end;margin-top: 30px">
      <div style="display: flex;flex-direction: column;gap: 10px;width:100%">
        <h3>Humankapital</h3>
        <div class="boxHolder" style="  background: rgb(220 137 185 / 20%);margin-bottom: 20px">

          <div class="Box" style="display: flex;flex:1">
            <div style="width: 100%;position: relative;display: block">
              <p style="font-weight: 300">Styrkor</p>
              <textarea name="" id="" cols="30" rows="10" style="width: 100%;background-color: transparent; border: unset;" ></textarea>
            </div>
            <div style="width: 100%;position: relative;display: block;border-left: 2px solid black;padding-left: 10px;padding-right: 10px">
              <p style="font-weight: 300">Svagheter</p>
              <textarea name="" id="" cols="30" rows="10" style="width: 100%;background-color: transparent; border: unset;" ></textarea>
            </div>
          </div>

        </div>
        <h3>Kundkapital </h3>
        <div class="boxHolder" style="  background: rgb(243 146 0 / 40%);">

          <div class="Box" style="display: flex;flex:1">
            <div style="width: 100%;position: relative;display: block">
              <p style="font-weight: 300">Styrkor</p>
              <textarea name="" id="" cols="30" rows="10" style="width: 100%;background-color: transparent; border: unset;" ></textarea>
            </div>
            <div style="width: 100%;position: relative;display: block;border-left: 2px solid black;padding-left: 10px;padding-right: 10px">
              <p style="font-weight: 300">Svagheter</p>
              <textarea name="" id="" cols="30" rows="10" style="width: 100%;background-color: transparent; border: unset;" ></textarea>
            </div>
          </div>

        </div>
      </div>

    </div>

    <pageFooter style="padding-top: 30px"></pageFooter>
  </div>
</template>

<script>
import pageHeader from "@/components/pageHeader";
import pageFooter from "@/components/pageFooter";
import {useCounterStore} from "@/state/pinia/stefan";

export default {
  name: "fourthPage",
  components: {
    pageHeader, pageFooter
  },
  computed: {
    order() {
      // Accessing the store inside a computed property
      const store = useCounterStore();
      return store.order; // Now correctly returns the order from the store
    }
  },
  methods: {
    resetTo(ob, key, val) {
      const store = useCounterStore();
      if (store.order[ob][key] > val) {
        store.order[ob][key] = val
      }

    },
    resetToOne(ob) {
      const store = useCounterStore();
      store.order[ob].goodWill = 1
      store.order[ob].hallbar = 1
    },
  }
}
</script>

<style scoped>

.boxHolder {
  border-radius: 15px;
  padding: 0 15px;
}

.boxHolder h3 {
  font-size: 16pt;
  font-weight: 400;
  padding-bottom: 10px;
}

.boxHolder span {
  font-size: 10pt;
  font-weight: 300;
}

.boxHolder label {
  font-weight: 300;
  font-size: 16pt;
  width: 210px;
}

.Box {
  display: flex;
  gap: 15px;
  height: 350px;
}
.Box textarea{
  height: 290px;
}
.Box input {
  background-color: white;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 2px;
  height: 30px;
  width: 60px;

}
</style>
