<template>
  <repportMain></repportMain>
</template>

<script>
import repportMain from './components/repportMain.vue'

export default {
  name: 'App',
  components: {
    repportMain
  }
}
</script>

<style>
.d-none{
  display: none;
}
body {
  background: #f1efef;
}
page {
  background: white;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
}
page {
  height: 29.7cm;
}
h2,h3,h4{
  margin: 0;
}
*, *::before, *::after {
  box-sizing: border-box;
}
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#app{
  width: 21cm;
  float: none;
  margin: auto;
  font-family: 'Laca';
}
.stefanBtn{
  cursor: pointer;
}
.stefanBtn:hover{
  opacity: 0.8;
}
.footer{
  border-top: 2px solid #000;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  margin-top: 10px

}
.footer img{
  height: 80px;
}
@font-face {
  font-family: 'Laca';
  src: url('./assets/fonts/Laca-BlackItalic.woff2') format('woff2'),
  url('./assets/fonts/Laca-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Laca';
  src: url('./assets/fonts/Laca-Black.woff2') format('woff2'),
  url('./assets/fonts/Laca-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Laca';
  src: url('./assets/fonts/Laca-RegularItalic.woff2') format('woff2'),
  url('./assets/fonts/Laca-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Laca';
  src: url('./assets/fonts/Laca-ExtraLightItalic.woff2') format('woff2'),
  url('./assets/fonts/Laca-ExtraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Laca';
  src: url('./assets/fonts/Laca-ExtraLight.woff2') format('woff2'),
  url('./assets/fonts/Laca-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Laca';
  src: url('./assets/fonts/Laca-SemiBoldItalic.woff2') format('woff2'),
  url('./assets/fonts/Laca-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Laca Book';
  src: url('./assets/fonts/Laca-Book.woff2') format('woff2'),
  url('./assets/fonts/Laca-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Laca';
  src: url('./assets/fonts/Laca-Regular.woff2') format('woff2'),
  url('./assets/fonts/Laca-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Laca';
  src: url('./assets/fonts/Laca-BoldItalic.woff2') format('woff2'),
  url('./assets/fonts/Laca-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Laca';
  src: url('./assets/fonts/Laca-MediumItalic.woff2') format('woff2'),
  url('./assets/fonts/Laca-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Laca';
  src: url('./assets/fonts/Laca-LightItalic.woff2') format('woff2'),
  url('./assets/fonts/Laca-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Laca';
  src: url('./assets/fonts/Laca-SemiBold.woff2') format('woff2'),
  url('./assets/fonts/Laca-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Laca';
  src: url('./assets/fonts/Laca-Medium.woff2') format('woff2'),
  url('./assets/fonts/Laca-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Laca';
  src: url('./assets/fonts/Laca-Bold.woff2') format('woff2'),
  url('./assets/fonts/Laca-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Laca';
  src: url('./assets/fonts/Laca-Light.woff2') format('woff2'),
  url('./assets/fonts/Laca-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Laca Book';
  src: url('./assets/fonts/Laca-BookItalic.woff2') format('woff2'),
  url('./assets/fonts/Laca-BookItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}


</style>
