<template>
  <div style="padding: 20px">
    <pageHeader ></pageHeader>
    <div style="display: flex; flex-direction: column; align-items: center; ">
      <div style="width: 400px">
        <h3 style="text-align: center">Det Dolda Kapitalet®</h3>
        <div style="display: flex; justify-content: space-around; width: 408px; padding-top: 20px; padding-bottom: 5px;">
          <div><strong>Tillgångar</strong></div>
          <div><strong>Goodwill</strong></div>
        </div>
      </div>
      <div
          style="border-top: 10px solid #000;width: 408px;border-radius: 5px;display: flex;    justify-content: space-evenly;">
        <div class="hallBar">
          <group>
            <groupNumber>
              <span class="numberGroup">{{order.Strukturkapital.goodWill}}</span>
              <span class="numberGroup">{{order.Strukturkapital.hallbar}}</span>
            </groupNumber>
            <div style="background-color: #009FE3;"
                 v-for="(row,index) in NumberToRows(order.Strukturkapital.goodWill)"
                 :key="'hu'+index"></div>
            <div style="background-color: #3AAA35;" v-for="(row,index) in NumberToRows(order.Strukturkapital.hallbar)"
                 :key="'hub'+index"></div>
          </group>
          <group>
            <groupNumber>
              <span class="numberGroup">{{order.Kundkapital.goodWill}}</span>
              <span class="numberGroup">{{order.Kundkapital.hallbar}}</span>
            </groupNumber>
           <div style="background-color: #F39200;"
                v-for="(row,index) in NumberToRows( order.Kundkapital.goodWill)"
                :key="'kb'+index"></div>
          <div style="background-color: #3AAA35;" v-for="(row,index) in NumberToRows(order.Kundkapital.hallbar)"
               :key="'kbh'+index"></div>
          </group>
          <group>
            <groupNumber>
              <span class="numberGroup">{{order.Humankapital.goodWill}}</span>
              <span class="numberGroup">{{order.Humankapital.hallbar}}</span>
            </groupNumber>
               <div style="background-color: #DC89B9;"
                    v-for="(row,index) in NumberToRows(order.Humankapital.goodWill)"
                    :key="'hk'+index"></div>
          <div style="background-color: #3AAA35;" v-for="(row,index) in NumberToRows(order.Humankapital.hallbar)"
               :key="'hkb'+index"></div>
          </group>
          <group>
            <groupNumber>
              <span class="numberGroup">{{order.Samhallskapital.goodWill}}</span>
              <span class="numberGroup">{{order.Samhallskapital.hallbar}}</span>
            </groupNumber>
             <div style="background-color: #95C11F;"
                  v-for="(row,index) in NumberToRows(order.Samhallskapital.goodWill)"
                  :key="'hk'+index"></div>
          <div style="background-color: #3AAA35;" v-for="(row,index) in NumberToRows(order.Samhallskapital.hallbar)"
               :key="'hkb'+index"></div>
       </group>
        </div>
        <div class="seperator" style="width: 9px;background-color: black;"></div>
        <div class="goodWill">
          <group>
            <groupNumber style="left:unset!important;right:-150px!important">
              <p style="font-size: 16pt;margin:5px">GoodWill:</p>
              <span class="numberGroup">{{GoodWill().length}}</span>
            </groupNumber>
          <div style="background-color: #FCEA10;" v-for="(gw,index) in GoodWill()" :key="'gw'+index"></div>
          </group>
          <group>
            <groupNumber style="left:unset!important;right:-150px!important">
              <p style="font-size: 16pt;margin:5px">Hållbarhet:</p>
              <span class="numberGroup">{{HallBar().length}}</span>
            </groupNumber>
          <div style="background-color: #3AAA35;" v-for="(gw,index) in HallBar()" :key="'hb'+index"></div>
          </group>
        </div>

      </div>
      <div>
        <img src="@/assets/btnSkapaTKors.svg" alt="" @click="order.page = 3" class="stefanBtn"
             style="  max-width: 180px;width: 180px;display: none" >
      </div>
    </div>

    <pageFooter style="padding-top: 30px"></pageFooter>
  </div>
</template>

<script>
import pageHeader from "@/components/pageHeader";
import pageFooter from "@/components/pageFooter";
import {useCounterStore} from "@/state/pinia/stefan";

export default {
  name: "ThirdPage",
  components: {
    pageHeader, pageFooter
  },
  computed: {

    order() {
      // Accessing the store inside a computed property
      const store = useCounterStore();
      return store.order; // Now correctly returns the order from the store
    }
  },
  methods: {
    NumberToRows(a) {
      if (this.order) {
        return Array.from({length: a}, (v, k) => k + 1);
      }
      return [];
    },
    GoodWill(a) {
      if (this.order) {
        let hk = this.order.Humankapital.goodWill
        let SK = this.order.Strukturkapital.goodWill
        let KK = this.order.Kundkapital.goodWill
        let SamK = this.order.Samhallskapital.goodWill
        let goodWill = hk + SK + KK + SamK
        return Array.from({length: goodWill}, (v, k) => k + 1);
      }
      return [];
    },
    HallBar(a) {
      if (this.order) {
        let hk = this.order.Humankapital.hallbar
        let SK = this.order.Strukturkapital.hallbar
        let KK = this.order.Kundkapital.hallbar
        let SamK = this.order.Samhallskapital.hallbar
        let goodWill = hk + SK + KK + SamK
        return Array.from({length: goodWill}, (v, k) => k + 1);
      }
      return [];
    },
    resetTo(ob, key, val) {
      const store = useCounterStore();
      if (store.order[ob][key] > val) {
        store.order[ob][key] = val
      }

    },
    resetToOne(ob) {
      const store = useCounterStore();
      store.order[ob].goodWill = 1
      store.order[ob].hallbar = 1
    },
  }
}
</script>

<style scoped>
.hallBar div {
  width: 192px;
  margin-bottom: 2px;
  height: 10pt !important;
}

.hallBar div:first-of-type {
  margin-top: 2px;
}

.goodWill div {
  width: 192px;
  margin-bottom: 2px;
  height: 10pt !important;
}

.goodWill div:first-of-type {
  margin-top: 2px;
}
group{
  position: relative;
}
groupNumber{
  position: absolute;
  left: -150px;
  top: 20px;
}
groupNumber span{
  display: block;
  margin-bottom: 10px;
  background: #EDEDED;
  padding: 10px 50px;
  border-radius: 8px;
  border: 1px solid #dbd8d8;
}

</style>
