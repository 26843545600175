import {defineStore} from "pinia";
export const useCounterStore = defineStore('stefan', {
    state: () => ({
        order:{
            date: new Date(),
            company:'',
            page:1,
            Kundkapital:{
                goodWill:4,
                hallbar:2
            },
            Humankapital:{
                goodWill:4,
                hallbar:2
            },
            Strukturkapital :{
                goodWill:4,
                hallbar:2
            },
            Samhallskapital  :{
                goodWill:4,
                hallbar:2
            },
        }

    }),
    getters: {

    },
    actions: {

    },
})

