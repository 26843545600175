<template>
    <div style="padding: 20px">
      <pageHeader></pageHeader>
      <div style="display: flex;justify-content: space-between;align-items: end;margin-top: 20px">
        <div style="display: flex;flex-direction: column;gap: 1px;width:100%;">
          <h3>Hållbarhet</h3>
          <div class="boxHolder" style="background: #ffffff; border: 3px solid #00ff00; margin-bottom: 0.5px;">
            <div class="Box" style="display: flex;flex:1">
              <div style="width: 100%;position:relative;display: block">
                <p style="font-weight: 300">Styrkor</p>
                <textarea name="" id="" cols="30" rows="10" style="width: 100%;background-color: transparent; border: unset;" placeholder="Skriv text här..."></textarea>
              </div>
              <div style="width: 100%;position: relative;display: block;border-left: 2px solid black;padding-left: 10px;padding-right: 10px">
                <p style="font-weight: 300">Svagheter</p>
                <textarea name="" id="" cols="30" rows="10" style="width: 100%;background-color: transparent; border: unset;" placeholder="Skriv text här..."></textarea>
              </div>
            </div>
          </div>

          <p>Vilka av FNs Globala mål följer företagets hållbarhetsarbete? (Kryssa i alternativ)</p>
          <h3>GLOBALA MÅLEN för hållbar utveckling</h3>
        </div>
      </div>
    </div>


  <div class="container1">
    <div class="container">
  <div class="box" style="background-color: #FF5733;">
    <input type="checkbox" class="checkbox">
    <span class="text">1. Ingen fattigdom</span>
  </div>
  <div class="box" style="background-color: #FFC300;">
    <input type="checkbox" class="checkbox">
    <span class="text">10. Minskad ojämnlikhet</span>
  </div>
  <div class="box" style="background-color: #C70039;">
    <input type="checkbox" class="checkbox">
    <span class="text">2. Ingen hunger</span>
  </div>
  <div class="box" style="background-color: #F4D35E;">
    <input type="checkbox" class="checkbox">
    <span class="text">11. Hållbara städer</span>
  </div>
  <div class="box" style="background-color: #BF1363;">
    <input type="checkbox" class="checkbox">
    <span class="text">3. God hälsa ochg välbefinnande</span>
  </div>
  <div class="box" style="background-color: #F9DC5C;">
    <input type="checkbox" class="checkbox">
    <span class="text">12. Hållbar konsumtion och produktion</span>
  </div>
  <div class="box" style="background-color: #F08A5D;">
    <input type="checkbox" class="checkbox">
    <span class="text">4. God utbildning för alla</span>
  </div>
  <div class="box" style="background-color: #BEDB39;">
    <input type="checkbox" class="checkbox">
    <span class="text">13. Bekämpa klimatförändringarna</span>
  </div>
  <div class="box" style="background-color: #F4D35E;">
    <input type="checkbox" class="checkbox">
    <span class="text">5. Jämställdhet</span>
  </div>
  <div class="box" style="background-color: #277DA1;">
    <input type="checkbox" class="checkbox">
    <span class="text">14. Hav och marina resurser</span>
  </div>
  <div class="box" style="background-color: #BF1363;">
    <input type="checkbox" class="checkbox">
    <span class="text">6. Rent vatten och sanitet för alla</span>
  </div>
  <div class="box" style="background-color: #FF4E00;">
    <input type="checkbox" class="checkbox">
    <span class="text">15. Ekosystem och biologisk mångfald</span>
  </div>
  <div class="box" style="background-color: #FFA400;">
    <input type="checkbox" class="checkbox">
    <span class="text">7. Hållbar energi</span>
  </div>
  <div class="box" style="background-color: #FFD034;">
    <input type="checkbox" class="checkbox">
    <span class="text">16. Fredliga och inkluderan samhällen</span>
  </div>
  <div class="box" style="background-color: #46ACC2;">
    <input type="checkbox" class="checkbox">
    <span class="text">8. Anständiga arbetsvillkor och ekonomisk tillväxt</span>
  </div>
  <div class="box" style="background-color: #46ACC2;">
    <input type="checkbox" class="checkbox">
    <span class="text">17. Genomförande och globalt partnerskap</span>
  </div>
  <div class="box" style="background-color: #008DB8;">
    <input type="checkbox" class="checkbox">
    <span class="text">9. Hållbar industri, innovationer och infrastruktur</span>
  </div>
</div>



  <pageFooter style="padding-top: 10px "></pageFooter>
    </div>
  </template>

  <script>
  import pageHeader from "@/components/pageHeader";
  import pageFooter from "@/components/pageFooter";
  import {useCounterStore} from "@/state/pinia/stefan";

  export default {
    name: "sixthPage",
    components: {
      pageHeader, pageFooter
    },
    computed: {
      order() {
        // Accessing the store inside a computed property
        const store = useCounterStore();
        return store.order; // Now correctly returns the order from the store
      }
    },
    methods: {
      resetTo(ob, key, val) {
        const store = useCounterStore();
        if (store.order[ob][key] > val) {
          store.order[ob][key] = val
        }

      },
      resetToOne(ob) {
        const store = useCounterStore();
        store.order[ob].goodWill = 1
        store.order[ob].hallbar = 1
      },
    }
  }
  </script>

  <style scoped>

  .boxHolder {
    border-radius: 15px;
    padding: 0 15px;
  }

  .boxHolder h3 {
    font-size: 16pt;
    font-weight: 400;
    padding-bottom: 10px;
  }

  .boxHolder span {
    font-size: 10pt;
    font-weight: 300;
  }

  .boxHolder label {
    font-weight: 300;
    font-size: 16pt;
    width: 210px;
  }

  .Box {
    display: flex;
    gap: 15px;
    height: 350px;
  }
  .Box textarea{
    height: 290px;
  }


.Box input {
  background-color: white;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 2px;
  height: 30px;
  width: 60px;

}

  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1px;
  }

  .box {
    width: calc(50% - 20px);
    height: 35px;
    margin-bottom: 7px;
    margin-top: 0.1px;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    position: relative;
  }

  .checkbox {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    position: absolute;
    left: 0;
    top: 40%;
    transform: translateY(-50%);
    cursor: pointer;
  }
   /* .checkbox::before {
    content: "X";
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 20px;
  }  */

  .text {
    margin-left: 40px;
  }



</style>
