<template>
  <div class="row">
    <div class="logoHolder" style="display: flex; justify-content: space-between;">
      <div class="formHolder">
        <div class="form-group" style="display: flex; flex-direction: column; gap: 10px;">
          <label for="" >Datum: <input type="date"  :value="order.date" disabled></label>
          <div class="">
            <label for="">Företag: <input type="text"  :value="order.company" disabled></label>
          </div>
        </div>

      </div>
      <img src="@/assets/logo.svg" alt="" class="logo">
    </div>
  </div>
</template>

<script>
import {useCounterStore} from "@/state/pinia/stefan";

export default {
  name: "pageHeader",
  computed: {
    order() {
      // Accessing the store inside a computed property
      const store = useCounterStore();
      return store.order; // Now correctly returns the order from the store
    }
  },
  mounted() {
    // Example of using the store in lifecycle hooks
    const store = useCounterStore();
    console.log("Current order:", store.order);
  },
}
</script>

<style scoped>
.logo{

  height: 120px;
}
 input{
  border-radius: 35px;
  border: 1px solid #000000;
  padding: 10px 10px;
  margin-left: 20px;
   background-color: white;
}
 label:not(:first-of-type){
  margin-top: 20px;
}
</style>
